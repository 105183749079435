import request from '@/utils/request'

// 统计当前区域志愿者人数、服务时长、活动场次等数据
export function getAreaAllData(params) {
  return request({
    url: `/v2/api/app/ap_member/member/query/center/static_data`,
    method: 'get',
    params
  })
}
// 根据id，获取当前区域下的子场所
export function getPlaceById(params) {
  return request({
    url: `/v2/api/app/ap_member/member/query/local/children/region`,
    method: 'get',
    params
  })
}
// 根据区域id，获取当前区域下的参加活动数量和团队数
export function getCountOfTeam(params) {
  return request({
    url: `/v2/api/app/ad_activity/activity/query/count/activity_group?regionId=${params.id}`,
    method: 'get',
  })
}

//  查看当前区域下的最新活动
export function getLastActivity(params) {
  return request({
    url: `/v2/api/app/ad_activity/activity/query/local_region/new_activity?regionId=${params.id}&page=${params.page}&size=${params.size}`,
    method: 'get',
  })
}
// 部门简介/v2/api/app/ap_member/member/queryRegionByInf
export function queryRegionByInfo(params) {
  return request({
    url: `/v2/api/app/ap_member/member/queryRegionByInfo`,
    method: 'get',
    params
  })
}
// 点击团队数量切换下面数据
export function queryTeam(params) {
  return request({
    url: `/v2/api/app/ap_member//member/select_team`,
    method: 'get',
    params
  })
}
export default { getAreaAllData, getPlaceById, getCountOfTeam, getLastActivity, queryRegionByInfo, queryTeam }