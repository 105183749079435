<template>
  <div class="activeDetail titlesTop">
    <headTitle title="简介" />
    <div class="block01 row">
      <div class="logo">
        <img src="../../assets/images/zhiyuanzheicon1.png" alt />
      </div>
      <div class="HeadLeft">
        <div class="t1">{{ simpleName }}</div>
        <div class="t2">
          <div class="imgicon">
            <img src="../../assets/images/zhiyuanzheicon2.png" alt />
          </div>
          <div class="text">负责人：{{ regionPerson ? regionPerson : "暂无" }}</div>
        </div>
        <div>
          <a :href="'tel:' + regionPhone" class="t2">
            <div class="imgicon">
              <img src="../../assets/images/zhiyuanzheicon3.png" alt />
            </div>
            <div class="text">
              联系电话：
              {{ regionPhone ? regionPhone : "暂无" }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="block02" v-if="allData">
      <div class="title">
        <span></span>基本信息
      </div>
      <div class="active_list">
        <div v-for="(item, index) in allData" :key="index">
          <div>
            <span class="active_num">{{ item.count }}</span>
            <span class="active_detail">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="block03">
      <div class="title">
        <span></span>组织介绍
      </div>
      <div class="content">{{ regionIntroduce ? regionIntroduce : "暂无" }}</div>
    </div>
  </div>
</template>

<script>
import ArmyBuildapi from '@/api/armyBuild'

export default {
  data() {
    return {
      allData: null,  // 所有服务数据
      params: {
        id: '', // 区域id
        appName: this.appName
      },
      RegionByInfo: null,
      simpleName: null,
      regionIntroduce: null,
      regionPerson: null,
      regionPhone: null
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.params.id = this.$route.query.id;
      this.getArmyData();
    }

    this.queryRegionByInfo()
  },
  methods: {
    // 获取当前区域志愿者人数、服务时长、活动场次等数据
    getArmyData() {
      let params = {
        appName: this.appName,
        id: this.params.id,
      }
      ArmyBuildapi.getAreaAllData(params).then((res) => {
        // console.log(res.data);
        if (res.success) {
          this.allData = res.data;
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 获取区域组织介绍/v2/api/app/ap_member//member/queryRegionByInf
    queryRegionByInfo() {
      let params = {
        appName: this.appName,
        id: this.params.id,
      }
      ArmyBuildapi.queryRegionByInfo(params).then((res) => {
        // console.log(res.data);
        if (res.success) {
          this.$nextTick(function () {
            this.RegionByInfo = res.data;
            this.simpleName = res.data.simpleName
            this.regionPerson = res.data.regionPerson
            this.regionPhone = res.data.regionPhone
            this.regionIntroduce = res.data.regionIntroduce
          })
        }
      }).catch((err) => {
        console.log(err);
      })
    },
  }
}
</script>

<style scoped lang="less">
img {
  max-width: 100%;
}
.activeDetail {
  .block01 {
    padding-top: 3vw;
    padding-bottom: 4vw;
    width: 96%;
    margin: 0 auto;
    .logo {
      width: 22%;
      margin-right: 3%;
    }
    .t1 {
      color: #333333;
      font-size: 5vw;
      font-weight: bolder;
      margin-bottom: 2vw;
    }
    .t2 {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1vw;
      .text {
        width: 100%;
        font-size: 15px;
      }
    }
    .t3 {
      display: flex;
      align-items: center;
      margin-bottom: 1vw;
    }
    .imgicon {
      width: 5vw;
      margin-right: 1vw;
    }
  }
  .block02 {
    width: 96%;
    padding-top: 3vw;
    padding-bottom: 4vw;
    margin: 0 auto;
  }
  .block03 {
    width: 96%;
    padding-top: 3vw;
    padding-bottom: 4vw;
    margin: 0 auto;
    .content {
      text-indent: 0.3rem;
      line-height: 0.54rem;
      font-size: 16px;
    }
  }
  .row {
    display: flex;
  }
  .line {
    width: 100%;
    height: 3vw;
    background: #e6e6e6;
  }
}
.title {
  span {
    display: inline-block;
    width: 1vw;
    height: 5vw;
    background: #1377e2;
    margin: 0 8px;
  }
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 5vw;
  font-weight: bolder;
  margin-bottom: 2vw;
}
.active_list {
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
  div {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .active_num {
    font-size: 5vw;
    font-weight: bolder;
  }
  .active_detail {
    font-size: 3.5vw;
    opacity: 0.8;
  }
}
</style>